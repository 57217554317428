import {FormValues} from '@wix/form-viewer';
import {FullNameValue} from '@wix/form-fields';
import {FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {DEFAULT_COUNTRY} from '../../constants';

export const getContactFormInitialState = ({
  checkoutSettings,
  contact,
  country = DEFAULT_COUNTRY,
}: {
  checkoutSettings: CheckoutSettingsModel;
  contact?: ContactModel;
  country?: string;
}): FormValues => {
  return {
    full_name: {
      first_name: contact?.firstName ?? '',
      last_name: contact?.lastName ?? '',
      country,
    },
    ...(checkoutSettings.phone.show ? {phone: contact?.phone ?? ''} : {}),
    ...(checkoutSettings.companyName.show ? {company_name: contact?.company ?? ''} : {}),
  };
};

export const getContactFormCurrentStateWithUpdatedCountry = ({
  contactFormValues,
  country,
}: {
  contactFormValues: FormValues;
  country?: string;
}): FormValues => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    ...contactFormValues,
    full_name: {
      ...fullName,
      country,
    },
  };
};

export const getContactDetailsFromContactFormValues = (
  contactFormValues: FormValues,
  checkoutSettings: CheckoutSettingsModel
): FullAddressContactDetails => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    firstName: fullName.first_name as string,
    lastName: fullName.last_name as string,
    ...(checkoutSettings.phone.show && contactFormValues.phone ? {phone: contactFormValues.phone as string} : {}),
    ...(checkoutSettings.companyName.show && contactFormValues.company_name
      ? {company: contactFormValues.company_name as string}
      : {}),
  };
};

export const mapContactModelToContactDetails = (contact?: ContactModel): FullAddressContactDetails => {
  return contact
    ? {
        firstName: contact.firstName ?? /* istanbul ignore next */ '',
        lastName: contact.lastName ?? /* istanbul ignore next */ '',
        ...(contact.phone ? {phone: contact.phone} : /* istanbul ignore next */ {}),
        ...(contact.company ? {company: contact.company} : /* istanbul ignore next */ {}),
        ...(contact.vatId ? /* istanbul ignore next */ {vatId: contact.vatId} : {}),
      }
    : /* istanbul ignore next */ {};
};
